import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './style.scss';
import paper, { Path, Point } from 'paper';

import { format, addDays, getYear, getMonth, getDate } from 'date-fns';
import cn from 'classnames';

import DATA from './data';

import 防控政策 from './assets/iconsvg/防控政策.svg';
import 科研医疗 from './assets/iconsvg/科研医疗.svg';
import 领导人活动 from './assets/iconsvg/领导人活动.svg';
import 经济政策 from './assets/iconsvg/经济政策.svg';
import 疫情通告 from './assets/iconsvg/疫情通告.svg';

import Intro1 from './assets/intro/1.svg';
import Intro2 from './assets/intro/2.svg';
import Intro3 from './assets/intro/3.svg';
import Intro4 from './assets/intro/4.svg';

const SOURCES = Object.keys(DATA);
const DATA_COLORS = {
  中国: {
    main: '#EE2D37',
    from: '#ED1F3E',
    to: '#EF6D16',
  },
  美国: {
    main: '#165AB9',
    from: '#013F67',
    to: '#200F70',
  },
  韩国: {
    main: '#00B899',
    from: '#00FFBB',
    to: '#00CEFF',
  },
  日本: {
    main: '#FFA500',
    from: '#FFD200',
    to: '#FF9300',
  },
  英国: {
    main: '#662B8D',
    from: '#AF00FF',
    to: '#5863CE',
  }
};

const headless = document.createElement('canvas');
paper.setup(headless);

// const COMPRESS_RATIO = 3;
// const REVEAL_RATIO = .05;
// const REVEAL_ZONE_SIZE = (COMPRESS_RATIO - 1) / (COMPRESS_RATIO - REVEAL_RATIO);
// const REVEAL_ZONE_ORIG = REVEAL_ZONE_SIZE * REVEAL_RATIO;
const ICON_SIZE = 14;
const COL_HEIGHT = 20;
const GRAPH_WIDTH_RATIO = 0.7;
const DETAIL_HEIGHT = 120;
const DAY_HEIGHT = DETAIL_HEIGHT;
// const SCROLL_RATIO = 0.1;
const COLLAPSE_ZONE_ROWS = 2;
const COLLAPSE_ZONE_HEIGHT = COLLAPSE_ZONE_ROWS * ICON_SIZE;
const CRITICAL_SECTION_HEIGHT = 240;
const STACK_COUNT = 8;
const CANVAS_MINIMUM = 5;
const DECAY_RATIO = 0.7;
const DECAY_THRESH = 0.000001;

function getPlacing(scroll, totDay) {
  if(scroll === null) return day => ({ ratio: day / totDay, collapsed: 'default' });

  const windowSize = window.innerWidth - 60 - COLLAPSE_ZONE_HEIGHT * 2;

  const windowBegin = scroll;
  const windowEnd = windowBegin + windowSize;
  return (day, forced = null) => {
    // Expansion window
    const selfExtended = forced ?? day * DAY_HEIGHT + CRITICAL_SECTION_HEIGHT;

    if(selfExtended >= windowEnd)
      return {
        ratio: (windowSize + COLLAPSE_ZONE_HEIGHT +
          (selfExtended - windowEnd) / ((totDay+1) * DAY_HEIGHT - windowEnd) * COLLAPSE_ZONE_HEIGHT
        ) / (window.innerWidth - 60),
        collapsed: 'end',
      };
    else if(selfExtended <= windowBegin)
      return {
        ratio: (selfExtended / windowBegin) * COLLAPSE_ZONE_HEIGHT / (window.innerWidth - 60),
        collapsed: 'begin',
      };

    const inlineOffset = selfExtended - windowBegin;

    let animation = 1;
    if(inlineOffset < CRITICAL_SECTION_HEIGHT)
      animation = inlineOffset / CRITICAL_SECTION_HEIGHT;
    if(inlineOffset > windowSize - CRITICAL_SECTION_HEIGHT)
      animation = (windowSize - inlineOffset) / CRITICAL_SECTION_HEIGHT;

    return {
      collapsed: null,
      ratio: (inlineOffset + COLLAPSE_ZONE_HEIGHT) / (window.innerWidth - 60),
      animation,
      towards: (inlineOffset > windowSize / 2) ? 'end' : 'begin',
    };
  }
}

function findNearest(dis, placing) {
  let minDist = Infinity;
  let result = 0;
  for(let i = 0; i*2 < dis.length; ++i) {
    const x = dis[i*2];
    const y = dis[i*2+1];

    if(x - placing < minDist) {
      minDist = x - placing;
      result = y;
    }
  }

  return result;
}

const COLORS = {
  '疫情通报': '#BF4C00',
  '防控政策': '#26B3CF',
  '防空措施': '#26B3CF',
  '防控措施': '#26B3CF',
  '科研医疗': '#5863CE',
  '领导人活动': '#BA3835',
  '对外援助': '#C0AC2D',
  '经济政策': '#C0AC2D',
};

const ICONS = {
  '疫情通报': 疫情通告,
  '防控政策': 防控政策,
  '防控措施': 防控政策,
  '防空措施': 防控政策,
  '科研医疗': 科研医疗,
  '领导人活动': 领导人活动,
  '对外援助': 经济政策,
  '经济政策': 经济政策,
};

const News = React.memo(({ news, x, y, expanded, animation, opacity }) => {
  const type = news.category;
  if(!(type in ICONS)) throw type;

  return <div className={cn("detail")} style={{
    color: COLORS[type],
    transform: `translate(${y}px, ${x}px) scale(${10 / 14 + 4 / 14 * animation})`,
  }}>
    <div className="detail-info">
      <img className="detail-icon" src={ICONS[type]} style={{ opacity }} />
      <div className="detail-cate" style={{ opacity: animation }}>{ type }</div>
    </div>
    <div style={{
      opacity: animation,
    }}>
      <div className="detail-date">{ news.date }</div>
      <div className="detail-text">{ news.text }</div>
    </div>
  </div>
});

const Column = React.memo(({ data, scroll, start, end, left, maxInfected, colors }) => {
  const ref = useRef(null);

  const totDayCnt = (new Date(end) - new Date(start)) / 1000 / 60 / 60 / 24 + 1;
  const place = getPlacing(scroll, totDayCnt);

  const height = window.innerWidth - 60 - COL_HEIGHT;
  const width = window.innerHeight / 2;

  // Scanning
  const [items, labels, tags] = useMemo(() => {
    const _items = [];
    const _labels = [];
    const _tags = [];

    let hasYear = false;
    let hasMonth = false;

    let itemLower = 0;

    for(let i = 0; i < totDayCnt; ++i) {
      const curDay = addDays(new Date(start), i);
      const curDayStub = format(curDay, 'yyyy/MM/dd');

      const news = data[curDayStub]?.news ?? [];
      const special = news.find(e => parseInt(e.label) !== 0);

      if(special) {
        _labels.push({
          day: i,
          special: parseInt(special.label),
        });
      }

      for(const n of news) {
        let place = i * DAY_HEIGHT + CRITICAL_SECTION_HEIGHT;
        if(place < itemLower) place = itemLower;
        _items.push({
          place,
          day: i,
          news: n,
          id: n.uuid,
        });
        itemLower = place + DETAIL_HEIGHT;
      }

      const date = getDate(curDay);
      const month = getMonth(curDay) + 1;
      const year = getYear(curDay);

      if(!hasYear || month === 1 && date === 1) {
        _tags.push({
          text: year,
          type: 'year',
          day: i,
        });
        hasYear = true;
      }

      if(!hasMonth || date === 1) {
        _tags.push({
          text: month + '月',
          type: 'month',
          day: i,
        });
        hasMonth = true;
      }

      _tags.push({
        text: `${month}/${date}`,
        type: 'date',
        day: i,
      });
    }

    return [_items, _labels, _tags];
  }, [start, end, data]);

  const firstMonthTag = tags.find(e => e.type === 'month');
  let lastMonthTag = firstMonthTag;
  for(let i = tags.length-1; i >= 0; --i) if(tags[i].type === 'month') {
    lastMonthTag = tags[i];
    break;
  }

  // Canvas
  const path = new Path();
  let lastRatio = -1;
  path.add(new Point(0, 0));
  for(let i = 0; i < totDayCnt; ++i) {
    const curDay = addDays(new Date(start), i);
    const curDayStub = format(curDay, 'yyyy/MM/dd');

    const cases = data[curDayStub]?.infected ?? 0;
    if(cases === undefined) continue;

    const { ratio } = place(i);

    let padding = CANVAS_MINIMUM;
    const x = cases / maxInfected * width * GRAPH_WIDTH_RATIO + padding;
    const y = ratio * (height + COL_HEIGHT);

    if(ratio < lastRatio) console.log(`Warning: lastRatio = ${lastRatio}, ratio = ${ratio}`);
    lastRatio = ratio;

    path.add(new Point(y, x));
  }
  path.smooth({ type: 'catmull-rom' });

  const [canvas, setCanvas] = useState(null);

  useEffect(() => {
    if(!canvas) return;

    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, width);

    gradient.addColorStop(0, colors.from);
    gradient.addColorStop(0.5, colors.to);

    ctx.clearRect(0, 0, height+COL_HEIGHT, width);
    ctx.strokeStyle = 'white';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, 0);
    let seg = path.segments[0];
    ctx.lineTo(seg.point.x, seg.point.y);

    let lastY = 0;
    let lastX = 0;
    for(; seg.next; seg = seg.next) {
      const cp1 = seg.point.add(seg.handleOut);
      const cp2 = seg.next.point.add(seg.next.handleIn);
      ctx.bezierCurveTo(
        cp1.x,
        cp1.y,

        cp2.x,
        cp2.y,

        seg.next.point.x,
        seg.next.point.y,
      );

      lastY = seg.next.point.y;
      lastX = seg.next.point.x;
    }
    // ctx.lineTo(0, lastY);
    ctx.lineTo(lastX, 0);
    ctx.fillStyle = gradient;
    ctx.fill();
  }, [data, scroll, canvas, maxInfected, start, end]);

  const onCanvas = useCallback((canvas) => {
    setCanvas(canvas);
  }, []);


  // Layout
  let minimal = 0;
  for(const label of labels) {
    const { ratio } = place(label.day);
    const loc = Math.max(minimal, ratio * height);
    label.loc = loc;
    minimal = label.loc + COL_HEIGHT;
  }

  minimal = 0;
  for(const tag of tags) {
    let forced = null;
    if(tag.type === 'month' && tag.type === 'month')
      forced = tag.day * DAY_HEIGHT + CRITICAL_SECTION_HEIGHT - 24;

    const { ratio, collapsed } = place(tag.day, forced);
    const loc = Math.max(minimal, ratio * height);
    const expanded = collapsed === null;

    tag.highlight = tag.type !== 'date' && expanded;
    tag.shown = collapsed === null || collapsed === 'default' && tag.type !== 'date';
    if(scroll !== null && tag.type === 'year') tag.shown = false;
    tag.loc = loc;
    tag.expanded = expanded;
    if(tag.shown)
      minimal = tag.loc + COL_HEIGHT;
  }

  // Highlight last year & month before expansion
  const expandIndex = tags.findIndex(e => e.expanded);
  if(expandIndex !== -1) {
    for(let i = expandIndex-1; i >= 0; --i)
      if(tags[i].type === 'month') {
        tags[i].highlight = true;
        break;
      }

    for(let i = expandIndex-1; i >= 0; --i)
      if(tags[i].type === 'year') {
        tags[i].highlight = true;
        break;
      }
  }

  let prefixSize = 0;
  let postfixSize = 0;
  const STACK_SIZE = (window.innerHeight / 2) - 36;
  let lastDefault = -Infinity;
  let lastDefaultOffset = 0;

  for(const n of items) {
    const { ratio, collapsed, animation, towards } = place(n.day, n.place);
    n.ratio = ratio;
    n.collapsed = collapsed;
    n.animation = animation;
    n.towards = n.collapsed || towards;
  }

  for(let i = 0; i < items.length; ++i) {
    if(items[i].towards === 'begin') {
      items[i].collapseCnt = prefixSize;
      ++prefixSize;
    } else {
      break;
    }
  }

  for(let i = items.length-1; i >= 0; --i) {
    if(items[i].towards === 'end') {
      items[i].collapseCnt = postfixSize;
      ++postfixSize;
    } else {
      break;
    }
  }

  for(const n of items) {
    const { ratio, collapsed, animation, towards, collapseCnt } = n;

    n.expanded = collapsed === null;
    n.animation = 0;

    let collapseLoc;
    let collapseCross;
    let collapseShown;

    if(collapseCnt >=  COLLAPSE_ZONE_ROWS * STACK_COUNT) {
      collapseShown = false;
      collapseCross = STACK_COUNT * ICON_SIZE + 12;
      collapseLoc = (COLLAPSE_ZONE_ROWS-1) * ICON_SIZE;
    } else {
        collapseLoc = Math.floor(collapseCnt / STACK_COUNT) * ICON_SIZE;
        collapseCross = (collapseCnt % STACK_COUNT) * ICON_SIZE + 12;
        collapseShown = true;
    }

    if(towards === 'end') {
      collapseLoc = height - collapseLoc - ICON_SIZE;
    }

    // if(left) collapseCross = collapseCross - (width - 48-32) + ICON_SIZE;
    if(left) collapseCross = collapseCross - ICON_SIZE;

    if(collapsed === 'begin' || collapsed === 'end') {
      n.cross = collapseCross;
      n.loc = collapseLoc;
      n.opacity = collapseShown ? 1 : 0;
    } else if(collapsed === 'default') {
      const placing = ratio * (window.innerWidth - 60 - ICON_SIZE);

      if(placing > lastDefault + ICON_SIZE) {
        let curPlacing = placing;
        for(const label of labels)
          if(curPlacing + ICON_SIZE >= label.loc && label.loc + COL_HEIGHT >= curPlacing)
            curPlacing = label.loc + COL_HEIGHT;
        lastDefault = curPlacing;
        lastDefaultOffset = 12;
      } else {
        lastDefaultOffset += ICON_SIZE;
      }

      n.cross = lastDefaultOffset;
      n.loc = lastDefault;
      n.opacity = collapseShown;

      if(left) {
        // n.cross -= (width-48-32) - ICON_SIZE;
      }
    } else {
      let defaultCross = 32;
      if(left)
        defaultCross = 120;
      n.cross = animation * defaultCross + (1-animation) * collapseCross;
      n.loc = ratio * height * animation + (1-animation) * collapseLoc;
      n.animation = animation;
      n.opacity = collapseShown ? 1 : animation;
    }
  }

  const cnts = [];
  for(let i = 0; i < totDayCnt; ++i) {
    if(i % 2 == 1) continue;
    const { ratio, collapsed } = place(i);
    if(collapsed) continue;
    const curDay = addDays(new Date(start), i);
    const curDayStub = format(curDay, 'yyyy/MM/dd');
    cnts.push({
      cnt: (data[curDayStub] ?? {}).infected ?? 0,
      at: ratio * height - 28,
    });
  }

  /* UI */
  function labelText(special) {
    if(special === 1) return '第一例病例';
    if(special === 2) return '第一例死亡病例';
    if(special === 3) return '封城';
    if(special === 4) return 'COVID 大流行';
  }

  const segments = [];
  segments.push({
    type: 'segment-increase',
    top: labels.length < 1 ? 0 : labels[0].loc + COL_HEIGHT,
    bottom: labels.length < 2 ? height : labels[1].loc,
  });

  if(labels.length >= 2)
    segments.push({
      type: 'segment-worsen',
      top: labels[1].loc + COL_HEIGHT,
      bottom: labels.length < 3 ? height : labels[2].loc,
    });

  if(labels.length >= 3)
    segments.push({
      type: 'segment-max',
      top: labels[2].loc + COL_HEIGHT,
      bottom: labels.length < 4 ? height : labels[3].loc,
    });

  if(labels.length >= 4)
    segments.push({
      type: 'segment-max',
      top: labels[3].loc + COL_HEIGHT,
      bottom: labels.length < 5 ? height : labels[4].loc,
    });

  return (
    <div className={cn("col-inner", { 'col-expanded': scroll !== null, 'col-left': left })} ref={ref}>
      { left && <>
        <div className={cn("tag first-tag extra-tag", { 'extra-tag-shown': !firstMonthTag.shown})}>{ firstMonthTag.text }</div>
        <div className={cn("tag last-tag extra-tag", { 'extra-tag-shown': !lastMonthTag.shown})}>{ lastMonthTag.text }</div>
      </>}

      { segments.map(({ type, top, bottom }, idx) => <div key={`seg-${idx}`} className={`segment ${type}`} style={{ transform: `translateX(${top}px) scaleX(${(bottom - top) / height})` }}></div>) }
      { labels.map(({ special, loc }) => <div key={special} className="hint" style={{ transform: `translateX(${loc}px)` }}>{ labelText(special) }</div>) }

      <canvas className="canvas" height={width} width={height+COL_HEIGHT} ref={onCanvas} />

      { items.map(({ news, loc, cross, expanded, animation, opacity, id }) => (
        <News key={id} className="news" y={loc} x={left ? -cross: cross} news={news} expanded={expanded} animation={animation} opacity={opacity} />
      ))}
      { left && tags.map(({ text, loc, shown, highlight }, idx) => <div className={cn("tag", { 'tag-highlight': highlight })} key={`tag-${idx}`} style={{
        transform: `translateX(${loc}px) scale(${highlight ? 1.1 : 1})`,
        opacity: shown ? 1 : 0
      }}>{ text }</div>) }

      { cnts.map(({ at, cnt }, idx) => <div className={cn("cnt")} key={`cnt-${idx}`} style={{
        transform: `translateX(${at}px)`,
      }}>{ cnt }</div>) }
    </div>
  );
});

let mouseDown = false;

function getRange(data) {
  const keys = Object.keys(data);

  let start = new Date(keys[0]);
  let end = new Date(keys[0]);

  for(const key of keys) {
    const d = new Date(key);

    if(d < start) start = d;
    if(d > end) end = d;
  }

  return [start, end];
}

function App() {
  const [left, setLeft] = useState(SOURCES.findIndex(e => e === '中国'));
  const [right, setRight] = useState(SOURCES.findIndex(e => e === '美国'));

  const [scroll, setScroll] = useState(null);

  const leftData = DATA[SOURCES[left]];
  const rightData = DATA[SOURCES[right]];

  const leftColor = DATA_COLORS[SOURCES[left]];
  const rightColor = DATA_COLORS[SOURCES[right]];

  const [start, end, totDay] = useMemo(() => {
    const [leftStart, leftEnd] = getRange(leftData);
    const [rightStart, rightEnd] = getRange(rightData);

    const bothStart = leftStart < rightStart ? leftStart : rightStart;
    const bothEnd = leftEnd > rightEnd ? leftEnd : rightEnd;

    const start = format(bothStart, 'yyyy/MM/dd');
    const end = format(bothEnd, 'yyyy/MM/dd');

    const totDay = (bothEnd - bothStart) / (24*60*60*1000);

    return [start, end, totDay];
  }, [leftData, rightData]);

  useEffect(() => {
    const ln = SOURCES[left].substr(0, 1);
    const rn = SOURCES[right].substr(0, 1);

    document.title = `${ln}${rn}疫情防控对比 | 持续更新`;
  }, [left, right]);

  const [selecting, setSelecting] = useState(null);
  const [held, setHeld] = useState(false);
  const pendingY = useRef(null);
  const pendingHeld = useRef(false);
  const pendingScroll = useRef(null);
  const updateScroll = s => {
    pendingScroll.current = s;
    setScroll(s);
  };

  function getY(e) {
    let y = e.clientX - 60;
    if(e.touches) {
      if(e.touches.length === 0) return 0;
      y = e.touches.item(0).clientX - 60;
    }
    return y;
  }

  function getX(e) {
    let x = e.clientX;
    if(e.touches) {
      if(e.touches.length === 0) return 0;
      x = e.touches.item(0).clientX;
    }
    return x;
  }

  const introHist = window.localStorage.getItem('covid-intro');
  const [intro, setIntro] = useState(introHist === 'done' ? null : 0);
  // const [intro, setIntro] = useState(0);
  const nextIntro = e => {
    e.stopPropagation();
    if(intro === 0) return;
    if(intro === 4) return;
    setIntro(intro + 1);
  }
  const closeIntro = e => {
    e.stopPropagation();
    setIntro(null);
    window.localStorage.setItem('covid-intro', 'done')
  }

  const totScrollHeight = CRITICAL_SECTION_HEIGHT * 2 + totDay * DAY_HEIGHT - window.innerWidth + 160;

  const move = useCallback(e => {
    if(!held) return;

    e.stopPropagation();
    const y = getY(e);
    const diff = y - pendingY.current;
    let next = scroll - diff;
    if(next < 0) next = 0;
    if(next > totScrollHeight) next = totScrollHeight;
    updateScroll(next);
    pendingY.current = y;
  }, [scroll, held]);

  const down = useCallback(e => {
    if(intro !== null) return;
    const y = getY(e);
    if(y < 0) return;
    if(selecting !== null) return;

    const xRatio = getX(e) / window.innerHeight;

    // if(xRatio < 0.25 || xRatio > 0.75) return;

    setHeld(true);
    e.stopPropagation();
    mouseDown = true;
    pendingHeld.current = true;
    pendingY.current = y;

    const ratio = y / (window.innerWidth - 60);
    if(scroll === null)
      updateScroll(ratio * totScrollHeight);
  }, [scroll, selecting, intro]);

  const up = useCallback(e => {
    setHeld(false);
    pendingHeld.current = false;
  }, [scroll, pendingScroll]);

  const maxInfected = useMemo(() => {
    let ret = 1;
    for(const key in leftData) {
      const { infected } = leftData[key];
      if(ret < infected) ret = infected
    }

    for(const key in rightData) {
      const { infected } = rightData[key];
      if(ret < infected) ret = infected
    }

    return ret;
  }, [leftData, rightData])

  useEffect(() => {
    let last = performance.now();
    let lastScroll = null;
    let speed = null;

    let halt = false;
    const frame = ts => {
      const diff = ts - last;

      if(pendingHeld.current && lastScroll !== null && pendingScroll.current !== null) {
        const sdiff = pendingScroll.current - lastScroll;
        speed = sdiff / diff;
      }

      if(!pendingHeld.current && speed !== null && pendingScroll.current !== null) {
        speed *= DECAY_RATIO;
        if(Math.abs(speed) < DECAY_THRESH) speed = 0;

        let next = lastScroll + speed * diff;
        if(next < 0) next = 0;
        if(next > totScrollHeight) next = totScrollHeight;
        updateScroll(next);
      }

      lastScroll = pendingScroll.current;

      if(halt) return;
      requestAnimationFrame(frame);
    }

    requestAnimationFrame(frame);

    return () => { halt = true };
  }, [totScrollHeight]);

  function colorToRGB(color) {
    const [,r, g, b] = color.match(/^#(..)(..)(..)/);
    const rn = parseInt(r, 16);
    const gn = parseInt(g, 16);
    const bn = parseInt(b, 16);

    return `${rn}, ${gn}, ${bn}`;
  }

  /*
  useEffect(() => {
    window.addEventListener('wheel', e => {
      let next = pendingScroll.current + e.deltaY;
      if(next < 0) next = 0;
      if(next > totScrollHeight) next = totScrollHeight;
      console.log(next);
      updateScroll(next);
    });
  }, []);
  */

  return (
    <div className="container" onMouseDown={down} onTouchStart={down} onMouseUp={up} onTouchEnd={up} onMouseMove={move} onTouchMove={move}>
      <div className="graph-hint">每日新增人数</div>
      <div className="cols">
        <div className="col" style={{
          '--primary': colorToRGB(leftColor.main),
        }}>
          <div className="name-tag" onClick={() => setSelecting('left')}>
            { SOURCES[left] }
            <div className="arrow-down"></div>
          </div>
          <Column data={leftData} scroll={scroll} start={start} end={end} left={true} maxInfected={maxInfected} colors={leftColor} />
        </div>
        <div className="col" style={{
          '--primary': colorToRGB(rightColor.main),
        }}>
          <div className="name-tag" onClick={() => setSelecting('right')}>
            { SOURCES[right] }
            <div className="arrow-down"></div>
          </div>
          <Column data={rightData} scroll={scroll} start={start} end={end} left={false} maxInfected={maxInfected} colors={rightColor} />
        </div>
      </div>

      <div className="terminal" style={{
        opacity: scroll === null ? 0 : Math.max((scroll - totScrollHeight + 80) / 80, 0)
      }}>
        数据截止至 { end }，持续更新中...
      </div>

      <div className={cn("backdrop", { 'backdrop-active': selecting !== null })} onClick={() => setSelecting(null)}>
        <div className="select-dialog">
          { SOURCES.map((e, i) => <div key={i} className="select-entry" onClick={() => {
            if(selecting === 'left') setLeft(i);
            else setRight(i);

            updateScroll(null);
          }}>{ e }</div>) }
        </div>
      </div>
      <div className={cn("back", { 'back-shown': scroll !== null })} onClick={e => {
        updateScroll(null);
        e.stopPropagation();
      }}>返回</div>

      <div className={cn("backdrop", { 'backdrop-active': intro !== null })} onClick={nextIntro} onTouch={nextIntro}>
        { intro === 0 && (
          <div class="intro-home">
            <div class="intro-title">
              各国疫情资讯时间线 | 持续更新
            </div>

            <div class="intro-hint">
              清华大学自然语言处理与社会人文计算实验室整理疫情主要发生国相关防控资讯，结合每日新增构成此时间线。仅供参考，欢迎分享。
            </div>
            <div class="intro-confirm" onClick={() => setIntro(1)}>好的</div>
          </div>
        )}

        { intro === 1 && (
          <img src={Intro1} class="intro-1"></img>
        )}

        { intro === 2 && (
          <img src={Intro2} class="intro-2"></img>
        )}

        { intro === 3 && (
          <img src={Intro3} class="intro-3"></img>
        )}

        { intro === 4 && (<>
          <div className="cols">
            <div className="col" style={{
              '--primary': colorToRGB(leftColor.main),
            }}>
              <div className="name-tag">
                { SOURCES[left] }
                <div className="arrow-down"></div>
              </div>
            </div>
            <div className="col" style={{
              '--primary': colorToRGB(rightColor.main),
            }}>
              <div className="name-tag">
                { SOURCES[right] }
                <div className="arrow-down"></div>
              </div>
            </div>
          </div>
          <img src={Intro4} class="intro-4"></img>
          <div class="intro-confirm" onClick={closeIntro}>好的</div>
        </>)}
      </div>
    </div>
  );
}

export default App;
